.footer-container {
  text-align: center;
  padding: 1em 0 1em 0;
  color: white;
  background-color: var(--quaternary-color);
}

footer a {
  text-decoration: none;
  color: white;
  border-bottom: 1px solid white;
}

footer a:hover {
  border-bottom: none;
}

.heart {
  color: red;
}
