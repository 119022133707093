.nav {
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: fixed;
  z-index: 9000;
  background: var(--quaternary-color);
  transition: top 0.5s;
  line-height: 1em;
  font-weight: 600;
  padding: 5px 0;
}

.ak-icon {
  width: 35px;
}

.nav-icon,
.nav-ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5vw 0.1vw;
  margin: 0.2em 5em 0.2em 1em;
}

.nav-ul {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
}

.nav-a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.15em;
  margin: 0 2.5vw;
  padding: 0.5vw;
  border-bottom: 2px solid transparent;
  transition: 0.5s ease-in-out;
}

.nav-a:hover {
  border-bottom: 2px solid #ffffff;
}

.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}

@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}

@media only screen and (max-width: 768px) {
  .nav {
    padding: 1vw;
  }

  .nav-ul {
    display: none;
  }
}

@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}
