.skills-container {
  width: 75vw;
  max-width: 1000px;
}

.select-skills {
  display: block;
  font-weight: 600;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100px;
  box-sizing: border-box;
  margin: 0 auto 3em auto;
  border: 1px solid #aaa;
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.select-skills:hover {
  box-shadow: 0 0 3px #888888;
  -webkit-box-shadow: 0 0 3px #888888;
  cursor: pointer;
}

.select-skills:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: thin;
}

.select-skills option {
  margin: 0 auto;
}

.skills-icons {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.skills-icons a {
  text-decoration: none;
  color: var(--tertiary-color);
  font-weight: 600;
  margin: 1.25em;
}

.skills-icons i {
  font-size: 4em;
  padding: 0.25em;
}

.skills-icons p {
  margin: 0.5em auto;
}

.skills-icons a:active {
  -webkit-animation: scale-up-center 0.2s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@media only screen and (max-width: 768px) {
  .skills-icons {
    padding-top: 1em;
    margin: 0 -1em;
  }

  .skills-icons i {
    font-size: 3em;
  }

  .skills-icons p {
    font-size: 0.9em;
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
